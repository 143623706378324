html {
  font-family: "Times New Roman", serif;
}

.top-bar {
  background-color: hsl(120, 100%, 0%);
  padding: 10px 0;
  color: white;
}

.top-bar h1 {
  margin: 0;
  text-align: center;
}

#settings-bar {
  background-color: hsl(120, 35%, 90%);
  padding: 10px 0;
  margin-bottom: 20px;
  text-align: center;
}

#settings-bar form {
  display: flex;
  justify-content: center;
  gap: 0.5em;
  margin: 0 0.5em;
  flex-wrap: wrap;
}

.setting-selector {
  flex: 1;
  display: inline-block;
  text-align: left;
  max-width: 250px;
}

.setting-selector label {
  display: block;
  font-weight: 600;
  font-size: 16px;
  padding-left: 0.3rem;
  margin-bottom: 0.3rem;
}

.setting-selector select {
  border: none;
  padding: 0.3rem 2rem 0.3rem 0.6rem;
  border-radius: 0.3rem;
  text-align: left;
  font-size: 16px;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath fill='%23666' d='M8.187 11.748l6.187-6.187-1.06-1.061-5.127 5.127L3.061 4.5 2 5.561z'/%3E%3C/svg%3E");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  background-color: hsl(120, 0%, 100%);
}

#instrument-selector select {
  min-width: 120px;
}

#tuning-selector select {
  min-width: 235px;
}

@media only screen and (min-width: 500px) {
  #settings-bar form {
    gap: 1em;
    margin: 0 1em;
  }

  .setting-selector label {
    font-size: 16px;
  }

  .setting-selector select {
    font-size: 18px;
  }

  #instrument-selector select {
    min-width: 130px;
  }

  #tuning-selector select {
    min-width: 250px;
  }
}


.circle-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto 10px;
}

#circle-canvas {
  display: block;
  width: 100%;
  aspect-ratio: 1;
  font-family: "Gothic A1", Arial, Helvetica, sans-serif;
}

.fretboard-container {
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;
  margin: 0 auto 40px;
}

#fretboard-canvas {
  display: block;
  width: 1200px;
  height: 300px;
}

.piano-container {
  width: 100%;
  max-width: 1200px;
  overflow-x: auto;
  margin: 0 auto 40px;
}

.chord-variations-section {
  width: 100%;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto 40px auto;
  background-color: hsl(0, 0%, 85%);
  padding: 10px;
  text-align: center;
}

.piano-instructions {
  margin: 0 0 10px 0;
}

.chord-variations-bar {
  width: 100%;
  box-sizing: border-box;
}

.chord-variation-button {
  border: 1px solid hsl(0, 0%, 50%);
  border-radius: 5px;
  background-color: white;
  padding: 3px 7px;
  font-size: 17px;
  margin: 3px 5px 3px 0;
  box-shadow: 0 2px 3px hsl(0, 0%, 70%);
  font-family: "Gothic A1", Arial, Helvetica, sans-serif;
}

.chord-variation-button.active {
  background-color: black;
  color: white;
  border: 1px solid black;
}

#piano-canvas {
  display: block;
  width: 1200px;
  height: 150px;
}

.chords-in-key-toggle {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.chords-in-key-toggle input {
  margin-right: 5px;
}
